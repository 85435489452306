<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90-l w-100 center pa4">
                <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
                    <div>
                        <p class="pb2 f3 b">
                            Price Adjustment
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="priceModal = true"
                                    style="cursor: pointer; padding-bottom: 2px"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="priceModal">
                            You can generate a report for the performance of your business on a daily, weekly or monthly basis.
                            Click <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>
                </div>
                <div class="w-90-l w-100 center">
                    <div>
                        <p class="font16 b pv2">Do you want to update Prices for all Product, Service or Both ?</p>
                        <p class="font16" style="color: #686868">
                            Price helps you to know how to bill your clients in helping your business grow effectively.
                        </p>

                        <div class="flex flex-wrap gap8 pv4 pointer">
                            <div :class="{ activeTypes: activeType === 1 }" @click="setActive(1)" class="box-border w-30 pa4 b">
                                Product
                            </div>
                            <div :class="{ activeTypes: activeType === 2 }" @click="setActive(2)" class="box-border w-30 pa4 b">
                                Service
                            </div>
                            <div :class="{ activeTypes: activeType === 3 }" @click="setActive(3)" class="box-border w-30 pa4 b">
                                Both
                            </div>
                        </div>
                        <div class="font16 b">
                            <p>
                                I want to
                                <span class="mr3 ml3"
                                    ><select>
                                        <option>Increase</option>
                                        <option>Decrease</option>
                                    </select></span
                                >
                                <span class="mr3 ml3"
                                    ><select>
                                        <option>Selling Price</option>
                                        <option>Buying Price</option>
                                        <option>Both Selling and Buying price</option>
                                    </select></span
                                >
                                of my business.
                            </p>
                        </div>
                    </div>
                    <div class="pa3 mt3" style="background: #f8f9fb">
                        <p>By</p>
                        <p class="mv3">
                            <input class="w-10-l w-30 pv2 box-border pl2 mr3" type="number" />
                            <select class="w-20-l w-60">
                                <option>Percentage (%)</option>
                                <option>Fixed</option>
                            </select>
                        </p>
                        <div class="flex w-30-l w-60 gap16 justify-between">
                            <div
                                :class="{ activeOptions: activeOption === 1 }"
                                @click="
                                    setActiveOption(1);
                                    toggleExcept()
                                "
                                class="b blueColor"
                            >
                                Except
                            </div>
                            <div
                                :class="{ activeOptions: activeOption === 2 }"
                                @click="
                                    setActiveOption(2);
                                    toggleRoundUp()
                                "
                                class="b blueColor"
                            >
                                Round up
                            </div>
                        </div>
                        <div v-if="showExcept" class="mt2">
                            Where the price is
                            <select class="ml2 mr2">
                                <option>Greater than</option>
                                <option>Greater than or equal to</option>
                                <option>Less than</option>
                                <option>Less than or equal to</option>
                            </select>
                            <input class="w-20 pv2 box-border pl2 ml3" type="number" />
                        </div>
                        <div v-if="showRoundUp" class="mt2">
                            Round to the nearest
                            <select class="ml2 w-20-l w-50" v-model="selectedOption">
                                <option value="hundred">Hundred</option>
                                <option value="thousand">Thousand</option>
                                <option value="none">Don't round up</option>
                            </select>
                            <p style="color: #6f6c6c" class="pt3">e.g. 123,456 will become {{ roundedValue.toLocaleString() }}</p>
                        </div>
                    </div>
                    <div class="pv3 mt3">
                        <p class="b pv2">
                            Apply this price changes to
                            <select class="w-30 ml3 mr3">
                                <option>All</option>
                            </select>
                            Categories.
                        </p>
                        <div class="b pv2 w-80-l w-100 flex flex-wrap items-center gap8">
                          <div> in</div>
                            <!-- <select class="w-30 ml3 mr3">
                                <option>All</option>
                            </select> -->
                            <div>
                                <vue3-tags-input
                                    v-model:tags="state.selectedBusinesses"
                                    v-model="state.business"
                                    :select="true"
                                    :select-items="state.destinationBusinesses"
                                    @on-select="handleSelectedTag"
                                    @on-tags-changed="handleChangeTag"
                                    placeholder="Select business"
                                    class="w-50-l w-100"
                                    required
                                >
                                    <template #item="{ tag }">
                                        {{ tag.text }}
                                    </template>
                                    <template #no-data> No business </template>
                                    <template #select-item="tag">
                                        {{ tag.text }}
                                    </template>
                                </vue3-tags-input></div
                            >
                            Business/Store/Branch.
                        </div>
                
                    </div>
                    <div class="pv3">
                        <p class="b pb2">Filters</p>
                        <p style="color: #a19b9b">
                            These filters are used when a new person enters a campaign and before sending messages to check
                            whether or not people match specific conditions. They can also be used as exit criteria below.<a
                                class="b"
                                href="#"
                                style="color: black"
                            >
                                Learn more</a
                            >
                        </p>
                    </div>
                    <div class="mt3 flex" style="justify-content: end"><button>Submit</button></div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref, onMounted, computed, reactive } from 'vue'
import AppWrapper from '@/layout/AppWrapper.vue'
import Vue3TagsInput from 'vue3-tags-input'
import { useStore } from 'vuex'

export default {
    name: 'PriceAdjustment',
    components: { AppWrapper, Vue3TagsInput },

    setup() {
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const priceModal = ref(false)
        const activeType = ref(1)
        const showExcept = ref(false)
        const showRoundUp = ref(false)
        const selectedOption = ref('hundred')
        const activeOption = ref(0)

        const roundedValue = computed(() => {
            const value = 123456
            switch (selectedOption.value) {
                case 'hundred':
                    return Math.round(value / 100) * 100
                case 'thousand':
                    return Math.round(value / 1000) * 1000
                default:
                    return value
            }
        })

        const state = reactive({
            businesses: [],
            allBusinesses: [],
            sourceBusiness: '',
            selectedBusinesses: [],
            business: '',
        })

        const setActive = (index) => {
            activeType.value = index
        }

        const setActiveOption = (index) => {
            activeOption.value = index
        }
        const toggleExcept = () => {
            showExcept.value = true
            // showRoundUp.value = false
        }
        const toggleRoundUp = () => {
            showRoundUp.value = true
            // showExcept.value = false
        }

        const handleSelectedTag = (tag) => {
            state.selectedBusinesses.push(tag)
            state.destinationBusinesses = state.destinationBusinesses.filter((myTag) => myTag.text !== tag.text)
        }

        const handleChangeTag = (tags) => {
            state.selectedBusinesses = tags
        }

        const setBusinesses = () => {
            store.dispatch('Settings/getBusinesses', true).then((resp) => {
                if (resp.status) {
                    state.businesses = resp.data
                    state.allBusinesses = resp.data
                    state.destinationBusinesses = state.businesses.map((business) => {
                        return { id: business.org, text: business.name }
                    })
                    state.sourceBusiness = userData.value?.orgId || ''
                }
            })
        }

        onMounted(() => {
            setBusinesses()
        })
        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (!(event.target.closest('.clickModalIcon') || event.target === modalIcon) && priceModal.value === true) {
                    closeModal()
                }
            })
        })

        const closeModal = () => {
            priceModal.value = false
        }
        return {
            state,
            priceModal,
            closeModal,
            activeType,
            setActive,
            showExcept,
            showRoundUp,
            toggleExcept,
            toggleRoundUp,
            selectedOption,
            roundedValue,
            activeOption,
            setActiveOption,
            handleSelectedTag,
            handleChangeTag,
        }
    },
}
</script>
<style scoped>
.activeTypes {
    border: 1px solid #132c8c;
}
.font16 {
    font-size: 16px;
}
.blueColor {
    color: #132c8c;
    cursor: pointer;
}
.activeOptions {
    text-decoration: underline;
}
</style>
